var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "edit-items-box",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            "label-width": "100px",
            "label-position": "left",
          },
        },
        [
          _c(
            "el-form-item",
            {
              staticClass: "m-r-120",
              attrs: { prop: "deviceName", label: "设备名称" },
            },
            [
              _c("el-input", {
                attrs: {
                  clearable: "",
                  size: "mini",
                  maxlength: "50",
                  placeholder: "请输入设备名称",
                },
                model: {
                  value: _vm.form.deviceName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "deviceName", $$v)
                  },
                  expression: "form.deviceName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "m-r-120",
              attrs: { prop: "code", label: "设备编号" },
            },
            [
              _c("el-input", {
                attrs: {
                  clearable: "",
                  size: "mini",
                  maxlength: "6",
                  placeholder: "请输入设备名称",
                },
                model: {
                  value: _vm.form.code,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "code", $$v)
                  },
                  expression: "form.code",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "m-r-120",
              attrs: { prop: "projectType", label: "设备类型" },
            },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    size: "mini",
                    clearable: "",
                    placeholder: "请选择设备类型",
                  },
                  model: {
                    value: _vm.form.projectType,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "projectType", $$v)
                    },
                    expression: "form.projectType",
                  },
                },
                _vm._l(_vm.pTypes, function (item) {
                  return _c("el-option", {
                    key: item.name,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c("biz-edit", {
            attrs: {
              "data-obj": _vm.form,
              "is-admin": _vm.user.tenantId === 0,
              "is-detail": false,
              "is-add": true,
            },
            on: { "select-company": _vm.onSelectCompany },
          }),
          _c(
            "el-form-item",
            { attrs: { prop: "siteId", label: "所属站点" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    placeholder: "请选择所属站点",
                    size: "mini",
                    filterable: "",
                    clearable: "",
                  },
                  on: { change: _vm.onSelectSite },
                  model: {
                    value: _vm.form.siteId,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "siteId", $$v)
                    },
                    expression: "form.siteId",
                  },
                },
                _vm._l(_vm.siteList, function (site) {
                  return _c("el-option", {
                    key: site.id,
                    attrs: { value: site.siteId, label: site.siteName },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "area", label: "所在地" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { placeholder: "请选择所在地", size: "mini" },
                  model: {
                    value: _vm.form.area,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "area", $$v)
                    },
                    expression: "form.area",
                  },
                },
                _vm._l(_vm.areaList, function (area, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { value: area, label: area },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "m-r-120",
              attrs: { prop: "information", label: "备注" },
            },
            [
              _c("el-input", {
                attrs: {
                  clearable: "",
                  maxlength: "50",
                  size: "mini",
                  type: "textarea",
                  placeholder: "请输入备注",
                },
                model: {
                  value: _vm.form.information,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "information", $$v)
                  },
                  expression: "form.information",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("dialog-footer", {
        attrs: {
          "reset-access": "device-edit-reset-btn",
          "submit-access": "device-edit-edit-btn",
        },
        on: { reset: _vm.onReset, submit: _vm.onSubmit },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }