var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.errors, function (error, index) {
      return _c("div", [
        _vm.selfTest[index] === "1"
          ? _c("div", { staticClass: "gw-error-box" }, [_vm._v(_vm._s(error))])
          : _vm._e(),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }