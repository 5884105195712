var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticStyle: { "margin-bottom": "7px" } },
        _vm._l(_vm.alertList, function (alert) {
          return _c("el-alert", {
            key: alert.id,
            staticClass: "alert-box",
            staticStyle: { "margin-bottom": "3px" },
            attrs: {
              type: "warning",
              "show-icon": "",
              title: alert.value,
              closable: false,
            },
          })
        }),
        1
      ),
      _c(
        "el-form",
        {
          ref: "search",
          attrs: { "label-width": "100px", "label-position": "left" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "设备类型" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择设备类型", size: "mini" },
                  on: { change: _vm.getDatas },
                  model: {
                    value: _vm.searchParams.projectType,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "projectType", $$v)
                    },
                    expression: "searchParams.projectType",
                  },
                },
                _vm._l(_vm.projectTypeList, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
        },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.deviceList,
                height: "294",
                "header-cell-style": {
                  background: "#F7F7F7",
                  color: "#2d2d2d",
                  "text-align": "center",
                },
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "45", align: "center" },
              }),
              _c("el-table-column", {
                attrs: { prop: "code", label: "设备编号", width: "80" },
              }),
              _c("el-table-column", {
                attrs: { prop: "deviceName", label: "项目编号", width: "120" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "masterCnt",
                  label: "下属主控数量",
                  width: "120",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "online",
                  label: "设备状态",
                  width: "80",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm.utils.isProductionMode()
                          ? [
                              scope.row.online
                                ? _c(
                                    "el-tag",
                                    {
                                      attrs: {
                                        type: "success",
                                        size: "small",
                                        effect: "dark",
                                      },
                                    },
                                    [_vm._v("在线")]
                                  )
                                : _c(
                                    "el-tag",
                                    {
                                      attrs: {
                                        size: "small",
                                        type: "info",
                                        effect: "dark",
                                      },
                                    },
                                    [_vm._v("离线")]
                                  ),
                            ]
                          : [
                              scope.row.code === "000000"
                                ? _c(
                                    "el-tag",
                                    {
                                      attrs: {
                                        type: "success",
                                        size: "small",
                                        effect: "dark",
                                      },
                                    },
                                    [_vm._v("在线")]
                                  )
                                : scope.row.online
                                ? _c(
                                    "el-tag",
                                    {
                                      attrs: {
                                        type: "success",
                                        size: "small",
                                        effect: "dark",
                                      },
                                    },
                                    [_vm._v("在线")]
                                  )
                                : _c(
                                    "el-tag",
                                    {
                                      attrs: {
                                        size: "small",
                                        type: "info",
                                        effect: "dark",
                                      },
                                    },
                                    [_vm._v("离线")]
                                  ),
                            ],
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "gatewayVersion",
                  label: "网关固件版本号",
                  width: "130",
                  align: "right",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "adminName",
                  label: "设备升级状态",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "update-time-log-box" }, [
                          _vm._v(
                            "上次升级时间：" +
                              _vm._s(
                                scope.row.gmtModify
                                  ? _vm.utils.formatTime(scope.row.gmtModify)
                                  : "未记录"
                              )
                          ),
                        ]),
                        scope.row.type === 1
                          ? _c(
                              "el-tag",
                              {
                                attrs: {
                                  type: "warning",
                                  size: "small",
                                  effect: "dark",
                                },
                              },
                              [_vm._v("升级中")]
                            )
                          : scope.row.type === 2
                          ? _c(
                              "el-tag",
                              {
                                attrs: {
                                  type: "danger",
                                  size: "small",
                                  effect: "dark",
                                },
                              },
                              [_vm._v("失败")]
                            )
                          : scope.row.type === 3
                          ? _c(
                              "el-tag",
                              {
                                staticClass: "waiting-tag",
                                attrs: { size: "small", effect: "dark" },
                              },
                              [_vm._v("等待中")]
                            )
                          : _c(
                              "el-tag",
                              {
                                attrs: {
                                  size: "small",
                                  type: "success",
                                  effect: "dark",
                                },
                              },
                              [_vm._v("正常")]
                            ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("el-pagination", {
            staticClass: "text-right m-t-10",
            attrs: {
              background: "",
              layout: "sizes, prev, pager, next, jumper",
              "page-sizes": [5, 10, 15],
              "page-size": _vm.pageData.size,
              total: _vm.pageData.total,
              "pager-count": 7,
              "current-page": _vm.pageData.current,
            },
            on: {
              "size-change": _vm.onSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "right-btns", staticStyle: { "margin-top": "20px" } },
        [
          _c(
            "el-button",
            {
              attrs: {
                size: "mini",
                type: "primary",
                disabled: _vm.utils.userReadOnly(_vm.user),
              },
              on: {
                click: function ($event) {
                  return _vm.onQuickUpgrade(false)
                },
              },
            },
            [_vm._v("设备一键升级")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                size: "mini",
                type: "primary",
                disabled: _vm.utils.userReadOnly(_vm.user),
              },
              on: {
                click: function ($event) {
                  return _vm.onQuickUpgrade(true)
                },
              },
            },
            [_vm._v("网关一键升级")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }