<template>
  <div>
    <el-table class="fixed-table" ref="table" :data="list" :height="height ? height: undefined" style="width: 100%" @selection-change="handleSelectionChange"
      :header-cell-style="{ background: '#F7F7F7', color: '#2d2d2d', 'text-align': 'center' }">
      <el-table-column type="selection" width="45" align="center" fixed="left"></el-table-column>
      <el-table-column type="index" label="序号" width="50" align="center" fixed="left">
        <template slot-scope="scope">
          {{ (scope.$index + 1) + (pageData.current - 1) * pageData.size }}
        </template>
      </el-table-column>
      <el-table-column prop="information" label="设备名称" width="245" fixed="left">
        <template slot-scope="scope">
          <div class="sass">
            <el-link type="primary" @click="gotoDeviceDetail(scope.row)">{{ scope.row.deviceName || scope.row.code }}</el-link>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="code" label="设备编号" align="center" width="80" fixed="left">
      </el-table-column>
      <el-table-column v-if="mode === 'admin'" prop="deviceName" label="项目编号" width="150" />
      <el-table-column prop="projectType" label="设备类型" align="center" width="80">
        <template slot-scope="scope">
          {{ pType470Map[scope.row.projectType] ? pType470Map[scope.row.projectType].label : "无数据" }}
        </template>
      </el-table-column>
      <el-table-column v-if="mode === 'list'" prop="online" label="在线状态" width="80" align="center">
        <template slot-scope="scope">
          <el-popover
            placement="top"
            width="270"
            trigger="hover"
            :content="getOnlineStatusPopver(scope.row)">
            <div slot="reference" class="name-wrapper">
              <div v-if="scope.row.online">
                <el-tag type="success" size="small" effect="dark">在线</el-tag>
              </div>
              <div v-else>
                <el-tag size="small" type="info" effect="dark">离线</el-tag>
              </div>
            </div>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column v-if="mode === 'admin'" prop="online" label="在线状态" width="320" align="center">
        <template slot-scope="scope">
          <span class="online-status-time-span">{{ getOnlineStatusPopver(scope.row) }}</span>
          <span v-if="scope.row.online">
            <el-tag type="success" size="small" effect="dark">在线</el-tag>
          </span>
          <span v-else>
            <el-tag size="small" type="info" effect="dark">离线</el-tag>
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="alarm" label="告警状态" width="80" align="center">
        <template slot-scope="scope">
          <el-tag effect="dark" class="tag-alarm-box" v-if="scope.row.alarm" type="danger" size="small" @click="onShowAlarm(scope.row)">告警</el-tag>
          <el-tag effect="dark" v-else type="success" size="small">正常</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="adminName" label="升级状态" width="150" align="center">
        <template slot-scope="scope">
          <el-popover
            placement="top"
            width="270"
            trigger="hover"
            :content="`上次升级时间：${ scope.row.gmtModify ? utils.formatTime(scope.row.gmtModify) : '无数据' }`">
            <div slot="reference" class="name-wrapper">
              <el-tag :type="(statusTxt[scope.row.type] || { tag : '无数据'}).tag"
                      :class="(statusTxt[scope.row.type] || { tag : '无数据'}).tag"
                      size="small" effect="dark">{{ (statusTxt[scope.row.type] || {label: "无数据"}).label }}
              </el-tag>
            </div>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column prop="gatewayState" v-if="mode==='admin'" label="网关协议" width="80" align="center">
        <template slot-scope="scope">
          {{ gwProtocols[scope.row.gatewayState].label }}
        </template>
      </el-table-column>
      <el-table-column prop="siteName" label="所属站点" min-width="300" align="center" />
      <el-table-column prop="businessName" label="所属公司" min-width="300" align="center" />
      <el-table-column prop="adminMobile" label="操作" :width="actionWidth" align="center" fixed="right"  v-if="mode === 'list'">
        <template slot-scope="scope">
            <el-button type="primary" style="margin-left: 10px;" size="mini" icon="el-icon-edit" @click="onEditDevice(scope.row)" />
            <el-popconfirm
              style="margin-left:10px;"
              icon="el-icon-info"
              icon-color="#FF0000"
              confirm-button-type="danger"
              title="删除操作不可恢复，您确认要删除么？"
              @confirm="onDelDevice(scope.row)"
            >
              <el-button type="danger" slot="reference" size="mini" icon="el-icon-delete" :disabled="utils.userReadOnly(user)" />
            </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="设备告警" destroy-on-close :close-on-press-escape="false" show-close
        :close-on-click-modal="true" v-if="dialogVisible.alarm" :visible="dialogVisible.alarm"
        @close="onCloseDialog('alarm', false)" width="600px">
        <alarm ref="alarm" :device-info="deviceInfo" />
      </el-dialog>
    <el-dialog title="网关告警" destroy-on-close :close-on-press-escape="false" show-close
        :close-on-click-modal="true" v-if="dialogVisible.gwselftest" :visible="dialogVisible.gwselftest"
        @close="onCloseDialog('gwselftest', false)" width="600px">
        <gw-self-test ref="gwselftest" :device-info="deviceInfo" @close-edit-modal="onCloseDialog('gwselftest', false, true)" />
      </el-dialog>
      <el-dialog :title="dialogTitle" destroy-on-close :close-on-press-escape="false" show-close
        :close-on-click-modal="true" v-if="dialogVisible.edit" :visible="dialogVisible.edit" width="600px"
        @close="onCloseDialog('edit', true)"
      >
        <edit ref="edit" :data="rowData" :visible="dialogVisible.edit" :is-add="isAdd"  @close-edit-modal="onCloseDialog('edit', true, true)" />
      </el-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import alarm from "./alarm";
import GwSelfTest from "./gw-self-test";
import edit from "./../device-edit";
import { delDevice } from '@/api/device';

export default {
  components: {
    alarm,
    edit,
    GwSelfTest
  },
  props: {
    mode: {
      type: String,
      default: ""
    },
    list: {
      type: Array,
      default: []
    },
    page: {
      type: Object,
      default:  () => {}
    },
    height: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      utils: $utils,
      isAdd: false,
      code: "",
      dialogTitle: "",
      actionWidth: 0,
      dialogVisible: {
        alarm: false,
        edit: false,
        sim: false,
        gwabnormality: false,
        gwselftest: false
      },
      rowData: null,
      pageData: {},
      deviceInfo: {},
      statusTxt: $statics.FIRMWARE_UPGRADE_STATUS_LIST,
      gwProtocols: $statics.GATEWAY_PROTOCOL_LIST,
      pType470Map: $statics.PROJECT_TYPE_470_MAP
    }
  },
  computed: {
    ...mapGetters(['user'])
  },
  watch: {
    page: function (v1, v2) {
      this.pageData = v1;
    },
    list: function () {
      this.$nextTick(() => {
        this.$refs.table.doLayout();
      });
    }
  },
  created () {
    this.actionWidth =  140;
    this.pageData = this.page;
  },
  methods: {
    getShort(str) {
      if (!str) {
        return "";
      }
      if (str.length < 20) {
        return str;
      } else {
        return str.slice(0, 20) + "...";
      }
    },
    onSearch () {
      this.emit("change");
    },
    gotoDeviceDetail (row) {
      this.$emit("detail", row);
    },
    handleSelectionChange (val) {
      this.$emit("select-change", val);
    },
    onShowAlarm (data) {
      this.deviceInfo = data;
      this.dialogVisible.alarm = true;
    },
    async onCloseDialog (refName, reloadFlg, ignore) {
      // 通过 emit 发送的关闭请求一律通过
      // 如果点击 close 图标则一律直接关闭
      if (ignore || !this.$refs[refName] || await this.$refs[refName].onClosed()) {
        // 设置 visable 后,会重新触发 close 所以需要先 remove edit
        delete this.$refs[refName];
        this.dialogVisible[refName] = false;
      }
      if (reloadFlg) {
        this.$emit("reload");
      }
      
    },
    getOnlineStatusPopver (row) {
      const time = row.onlineTime || $utils.formatArrayTime(row.gmtCreate);
      if (row.online) {
        return `最近上线时间：${time}`;
      } else {
        return `最后离线时间：${time}`;
      }
    },
    getGatewaySelfTest (value) {
      if (value.indexOf("1") < 0) {
        return "正常";
      } else {
        return "异常";
      }
    },
    async onDoError (data, name) {

      this.dialogVisible[name] = true;
      this.deviceInfo = data;
    },
    onEmsScreen (row) {
      window.open(`/#/ems-screen/${row.code}`);
    },
    onEditDevice () {
      this.$emit("edit");
    },
    // 编辑设备
    onEditDevice (row) {
      this.dialogVisible.edit = true;
      this.dialogTitle = "修改设备";
      this.rowData = row;
      this.isAdd = false;
    },
    async onDelDevice(row) {
      try {
        await delDevice(row.id);
        this.$emit("reload");
      } finally {
      }
    },
    // 添加设备
    onAddDevice () {
      this.dialogVisible.edit = true;
      this.dialogTitle = "添加设备";
      this.rowData = null;
      this.isAdd = true;
    },
  }
}
</script>
<style lang="scss" scoped>

.alarm-box {
  >div {
    display: inline-block;
    padding: 0px 8px;
    height: 24px;
    line-height: 24px;
    font-size: 12px;
  }

  >div:first-child {
    border-right:1px solid #FFFFFF;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
  }
  >div:last-child {
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
  }
}

.tag-alarm-box {
  cursor: pointer;
}

.tag-normal-box {
  background: #67C23A;
  color: #FFFFFF;
}

.online-status-time-span {
  margin-right: 15px;
  width: 200px;
}
</style>