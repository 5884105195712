<template>
  <div>
    <div style="margin-bottom: 7px;">
      <el-alert type="warning" show-icon style="margin-bottom:3px;" v-for="alert in alertList" class="alert-box" :key="alert.id" :title="alert.value" :closable="false" />
    </div>
    <el-form ref="search" label-width="100px" label-position="left">
      <el-form-item label="设备类型">
        <el-select v-model="searchParams.projectType" placeholder="请选择设备类型" size="mini" @change="getDatas">
          <el-option v-for="item in projectTypeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div v-loading="loading">
      <el-table :data="deviceList" style="width: 100%" height="294"  @selection-change="handleSelectionChange"
        :header-cell-style="{ background: '#F7F7F7', color: '#2d2d2d', 'text-align': 'center' }">
        <el-table-column type="selection" width="45" align="center" />
        <!-- <el-table-column prop="information" label="设备名称" /> -->
        <el-table-column prop="code" label="设备编号" width="80"/>
        <el-table-column prop="deviceName" label="项目编号" width="120" />
        <el-table-column prop="masterCnt" label="下属主控数量" width="120" align="center" />
        <el-table-column prop="online" label="设备状态" width="80" align="center">
          <template slot-scope="scope">
            <template v-if="utils.isProductionMode()">
              <el-tag v-if="scope.row.online" type="success" size="small" effect="dark">在线</el-tag>
              <el-tag v-else size="small" type="info" effect="dark">离线</el-tag>
            </template>
            <template v-else>
              <el-tag v-if="scope.row.code === '000000'" type="success" size="small" effect="dark">在线</el-tag>
              <el-tag v-else-if="scope.row.online" type="success" size="small" effect="dark">在线</el-tag>
              <el-tag v-else size="small" type="info" effect="dark">离线</el-tag>
            </template>
          </template>
        </el-table-column>
        <el-table-column prop="gatewayVersion" label="网关固件版本号" width="130" align="right" />
        <el-table-column prop="adminName" label="设备升级状态" align="center">
          <template slot-scope="scope">
            <div class="update-time-log-box">上次升级时间：{{ scope.row.gmtModify ? utils.formatTime(scope.row.gmtModify) : "未记录" }}</div>
            <el-tag v-if="scope.row.type === 1" type="warning" size="small" effect="dark">升级中</el-tag>
            <el-tag v-else-if="scope.row.type === 2" type="danger" size="small" effect="dark">失败</el-tag>
            <el-tag v-else-if="scope.row.type === 3" class="waiting-tag" size="small" effect="dark">等待中</el-tag>
            <el-tag v-else size="small" type="success" effect="dark">正常</el-tag>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination class="text-right m-t-10" background layout="sizes, prev, pager, next, jumper"
        :page-sizes="[5, 10, 15]" @size-change="onSizeChange"
        :page-size="pageData.size" :total="pageData.total" :pager-count="7" :current-page="pageData.current" @current-change="pageChange" />
    </div>
    <div class="right-btns" style="margin-top: 20px;">
      <el-button size="mini" type="primary" :disabled="utils.userReadOnly(user)" @click="onQuickUpgrade(false)">设备一键升级</el-button>
      <el-button size="mini" type="primary" :disabled="utils.userReadOnly(user)" @click="onQuickUpgrade(true)">网关一键升级</el-button>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { getDevices, oneUpgrade } from '@/api/device';
import { getMasterIDObject } from "@/utils/device";

export default {
  computed: {
    ...mapGetters(['user'])
  },
  data () {
    return {
      loading: false,
      utils: $utils,
      deviceList: [],
      multipleSelection: [],
      alertList: [
        {
          value: "一键升级只能升级到最新版本，请注意设备是否匹配升级程序！",
        },
        {
          value: "一键升级为远程处理，会存在升级失败的可能，请注意查看升级结果是否成功！",
        }
      ],
      searchParams: {
        projectType: "0"
      },
      projectTypeList: $statics.PROJECT_TYPE_LIST,
      pageData: {
        current: 1,
        size: 5,
        total: 0
      }
    }
  },
  created () {
    console.log("quick-upgrade");
    this.getDatas();
  },
	methods: {
    async getDatas () {
      this.loading = true;

      const params = {
        onlineStatus: 1,
        projectType: this.searchParams.projectType,
        page: this.pageData.size * (this.pageData.current - 1),
        size: this.pageData.size
      };

      try {
        const res = await getDevices(params);

        this.deviceList = res.data.map(obj => {
          const { masters, count } = getMasterIDObject(obj.systeminfo);
          obj.masterCnt = count;
          return obj;
        });
        this.pageData.total = res.paging.total;
      } catch (e) {
        this.pageData = {
          current: 1,
          size: this.pageData.size,
          total: 0
        };
        this.deviceList = [];
        this.$message.error(e.response.data.message || "读取失败");
      } finally {
        this.loading = false;
      }
    },
    handleSelectionChange (val) {
      this.multipleSelection = val;
    },
    onSizeChange (e) {
      this.pageData.size = e;
      this.pageData.current = 1;
      this.getDatas();
    },
    pageChange(e) {
      this.pageData.current = e;
      this.getDatas();
    },
    async onQuickUpgrade (flg) { // true: 网关，false：设备

      if (this.multipleSelection.length === 0) {
        this.$message.error("请至少选择一个设备");
        return;
      }

      for (const selection of this.multipleSelection) {
        if (selection.type === 1 || selection.type === 3) {
          this.$message.error(`设备：${selection.code} 正在升级，等选择其他设备进行升级` || "升级失败");
          this.loading = false;
          return;
        }
      }

      const typeList = [].concat($statics.PROJECT_TYPE_LIST);
      typeList.splice(5, 0, {});

      const params = {
        code: this.multipleSelection.map(obj => obj.code).join(),
        type: flg ? 1 : typeList[this.searchParams.projectType].firmware
      }

      try {
        this.loading = true;
        const res = await oneUpgrade(params);
        this.$message.success(`网关固件将被升级到：${res}`);
        this.$emit("close-edit-modal");
      } catch (e) {
        this.$message.error(e.response.data.message || "升级失败");
      } finally {
        this.loading = false;
      }
    },
		onClosed () {
			return true;
		}
	}
}
</script>
<style lang="scss" scoped>
.update-time-log-box {
  display:inline-block;
  width:250px;
  text-align: left;
}
</style>