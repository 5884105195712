var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          ref: "table",
          staticClass: "fixed-table",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            height: _vm.height ? _vm.height : undefined,
            "header-cell-style": {
              background: "#F7F7F7",
              color: "#2d2d2d",
              "text-align": "center",
            },
          },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", {
            attrs: {
              type: "selection",
              width: "45",
              align: "center",
              fixed: "left",
            },
          }),
          _c("el-table-column", {
            attrs: {
              type: "index",
              label: "序号",
              width: "50",
              align: "center",
              fixed: "left",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          scope.$index +
                            1 +
                            (_vm.pageData.current - 1) * _vm.pageData.size
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "information",
              label: "设备名称",
              width: "245",
              fixed: "left",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      { staticClass: "sass" },
                      [
                        _c(
                          "el-link",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.gotoDeviceDetail(scope.row)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(scope.row.deviceName || scope.row.code)
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "code",
              label: "设备编号",
              align: "center",
              width: "80",
              fixed: "left",
            },
          }),
          _vm.mode === "admin"
            ? _c("el-table-column", {
                attrs: { prop: "deviceName", label: "项目编号", width: "150" },
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: {
              prop: "projectType",
              label: "设备类型",
              align: "center",
              width: "80",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.pType470Map[scope.row.projectType]
                            ? _vm.pType470Map[scope.row.projectType].label
                            : "无数据"
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm.mode === "list"
            ? _c("el-table-column", {
                attrs: {
                  prop: "online",
                  label: "在线状态",
                  width: "80",
                  align: "center",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-popover",
                            {
                              attrs: {
                                placement: "top",
                                width: "270",
                                trigger: "hover",
                                content: _vm.getOnlineStatusPopver(scope.row),
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "name-wrapper",
                                  attrs: { slot: "reference" },
                                  slot: "reference",
                                },
                                [
                                  scope.row.online
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "el-tag",
                                            {
                                              attrs: {
                                                type: "success",
                                                size: "small",
                                                effect: "dark",
                                              },
                                            },
                                            [_vm._v("在线")]
                                          ),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "div",
                                        [
                                          _c(
                                            "el-tag",
                                            {
                                              attrs: {
                                                size: "small",
                                                type: "info",
                                                effect: "dark",
                                              },
                                            },
                                            [_vm._v("离线")]
                                          ),
                                        ],
                                        1
                                      ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2504298016
                ),
              })
            : _vm._e(),
          _vm.mode === "admin"
            ? _c("el-table-column", {
                attrs: {
                  prop: "online",
                  label: "在线状态",
                  width: "320",
                  align: "center",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "span",
                            { staticClass: "online-status-time-span" },
                            [
                              _vm._v(
                                _vm._s(_vm.getOnlineStatusPopver(scope.row))
                              ),
                            ]
                          ),
                          scope.row.online
                            ? _c(
                                "span",
                                [
                                  _c(
                                    "el-tag",
                                    {
                                      attrs: {
                                        type: "success",
                                        size: "small",
                                        effect: "dark",
                                      },
                                    },
                                    [_vm._v("在线")]
                                  ),
                                ],
                                1
                              )
                            : _c(
                                "span",
                                [
                                  _c(
                                    "el-tag",
                                    {
                                      attrs: {
                                        size: "small",
                                        type: "info",
                                        effect: "dark",
                                      },
                                    },
                                    [_vm._v("离线")]
                                  ),
                                ],
                                1
                              ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3217523482
                ),
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: {
              prop: "alarm",
              label: "告警状态",
              width: "80",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.alarm
                      ? _c(
                          "el-tag",
                          {
                            staticClass: "tag-alarm-box",
                            attrs: {
                              effect: "dark",
                              type: "danger",
                              size: "small",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.onShowAlarm(scope.row)
                              },
                            },
                          },
                          [_vm._v("告警")]
                        )
                      : _c(
                          "el-tag",
                          {
                            attrs: {
                              effect: "dark",
                              type: "success",
                              size: "small",
                            },
                          },
                          [_vm._v("正常")]
                        ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "adminName",
              label: "升级状态",
              width: "150",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-popover",
                      {
                        attrs: {
                          placement: "top",
                          width: "270",
                          trigger: "hover",
                          content: `上次升级时间：${
                            scope.row.gmtModify
                              ? _vm.utils.formatTime(scope.row.gmtModify)
                              : "无数据"
                          }`,
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "name-wrapper",
                            attrs: { slot: "reference" },
                            slot: "reference",
                          },
                          [
                            _c(
                              "el-tag",
                              {
                                class: (
                                  _vm.statusTxt[scope.row.type] || {
                                    tag: "无数据",
                                  }
                                ).tag,
                                attrs: {
                                  type: (
                                    _vm.statusTxt[scope.row.type] || {
                                      tag: "无数据",
                                    }
                                  ).tag,
                                  size: "small",
                                  effect: "dark",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    (
                                      _vm.statusTxt[scope.row.type] || {
                                        label: "无数据",
                                      }
                                    ).label
                                  ) + " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm.mode === "admin"
            ? _c("el-table-column", {
                attrs: {
                  prop: "gatewayState",
                  label: "网关协议",
                  width: "80",
                  align: "center",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.gwProtocols[scope.row.gatewayState].label
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3850227753
                ),
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: {
              prop: "siteName",
              label: "所属站点",
              "min-width": "300",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "businessName",
              label: "所属公司",
              "min-width": "300",
              align: "center",
            },
          }),
          _vm.mode === "list"
            ? _c("el-table-column", {
                attrs: {
                  prop: "adminMobile",
                  label: "操作",
                  width: _vm.actionWidth,
                  align: "center",
                  fixed: "right",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("el-button", {
                            staticStyle: { "margin-left": "10px" },
                            attrs: {
                              type: "primary",
                              size: "mini",
                              icon: "el-icon-edit",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.onEditDevice(scope.row)
                              },
                            },
                          }),
                          _c(
                            "el-popconfirm",
                            {
                              staticStyle: { "margin-left": "10px" },
                              attrs: {
                                icon: "el-icon-info",
                                "icon-color": "#FF0000",
                                "confirm-button-type": "danger",
                                title: "删除操作不可恢复，您确认要删除么？",
                              },
                              on: {
                                confirm: function ($event) {
                                  return _vm.onDelDevice(scope.row)
                                },
                              },
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  slot: "reference",
                                  type: "danger",
                                  size: "mini",
                                  icon: "el-icon-delete",
                                  disabled: _vm.utils.userReadOnly(_vm.user),
                                },
                                slot: "reference",
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2697043878
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.dialogVisible.alarm
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "设备告警",
                "destroy-on-close": "",
                "close-on-press-escape": false,
                "show-close": "",
                "close-on-click-modal": true,
                visible: _vm.dialogVisible.alarm,
                width: "600px",
              },
              on: {
                close: function ($event) {
                  return _vm.onCloseDialog("alarm", false)
                },
              },
            },
            [
              _c("alarm", {
                ref: "alarm",
                attrs: { "device-info": _vm.deviceInfo },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.dialogVisible.gwselftest
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "网关告警",
                "destroy-on-close": "",
                "close-on-press-escape": false,
                "show-close": "",
                "close-on-click-modal": true,
                visible: _vm.dialogVisible.gwselftest,
                width: "600px",
              },
              on: {
                close: function ($event) {
                  return _vm.onCloseDialog("gwselftest", false)
                },
              },
            },
            [
              _c("gw-self-test", {
                ref: "gwselftest",
                attrs: { "device-info": _vm.deviceInfo },
                on: {
                  "close-edit-modal": function ($event) {
                    return _vm.onCloseDialog("gwselftest", false, true)
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.dialogVisible.edit
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.dialogTitle,
                "destroy-on-close": "",
                "close-on-press-escape": false,
                "show-close": "",
                "close-on-click-modal": true,
                visible: _vm.dialogVisible.edit,
                width: "600px",
              },
              on: {
                close: function ($event) {
                  return _vm.onCloseDialog("edit", true)
                },
              },
            },
            [
              _c("edit", {
                ref: "edit",
                attrs: {
                  data: _vm.rowData,
                  visible: _vm.dialogVisible.edit,
                  "is-add": _vm.isAdd,
                },
                on: {
                  "close-edit-modal": function ($event) {
                    return _vm.onCloseDialog("edit", true, true)
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }