var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form-item",
    { staticClass: "m-r-120", attrs: { label: "设备编号", prop: "codes" } },
    [
      _c(
        "el-select",
        {
          staticStyle: { width: "100%" },
          attrs: {
            size: "small",
            disabled: _vm.disabled,
            filterable: "",
            multiple: _vm.multiple,
            clearable: "",
            placeholder: "请输入设备编号",
          },
          model: {
            value: _vm.form.codes,
            callback: function ($$v) {
              _vm.$set(_vm.form, "codes", $$v)
            },
            expression: "form.codes",
          },
        },
        _vm._l(_vm.simList, function (item) {
          return _c("el-option", {
            key: item.id,
            attrs: { label: item.code, value: item.code },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }