<template>
  <el-form-item class="m-r-120" label="设备编号" prop="codes">
    <el-select size="small" style="width:100%" :disabled="disabled" v-model="form.codes" filterable :multiple="multiple" clearable placeholder="请输入设备编号">
      <el-option
        v-for="item in simList"
        :key="item.id"
        :label="item.code"
        :value="item.code">
      </el-option>
    </el-select>
  </el-form-item>
</template>
<script>
import simApi from "@/api/sim";

export default {
  props: {
    data: {
      type: Object,
      default: null
    },
    multiple: {
      type: Object,
      default: false
    },
    form: {
      type: Object,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      inSimSelect: false,
      simList: [],
      simMap: {}
    }
  },
  async mounted () {
    if (this.data === null) {
      await this.selectSim();
    }
  },
  methods: {
    async selectSim () {
      try {
        this.inSimSelect = true;

        const simParams = {
          page:1,
          size: 99999,
          type: 0
        };

        const res = await simApi.list(simParams);
        this.simList = res.data;

        const simMap = {};

        for (const sim of this.simList) {
          simMap[sim.code] = sim;
        }

        this.simMap = simMap;

      } finally {
        this.inSimSelect = false;
      }
    },
    getAllSimMap () {
      return this.simMap;
    }
  }
}
</script>