<template>
  <div>
    <div v-for="(error, index) in errors">
      <div class="gw-error-box" v-if="selfTest[index] === '1'">{{ error }}</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    deviceInfo: undefined
  },
  data () {
    return {
      selfTest: "0000000000000000",
      errors: ["485 总线回应超时", "CAN 总监回应超时", "总控电压或温度读到了 0 条单体数据"]
    }
  },
  created () {
    this.selfTest = this.deviceInfo.gatewaySelfTest || "0000000000000000";
  },
  methods: {
    onClosed () {
      return true;
    }
  }
}
</script>