<template>
 <div v-loading="loading">
    <el-form ref="form" :model="form" :rules="rules" label-width="100px" label-position="left" class="edit-items-box">
          <el-form-item class="m-r-120" prop="deviceName" label="设备名称">
            <el-input v-model="form.deviceName" clearable size="mini" maxlength="50" placeholder="请输入设备名称" />
          </el-form-item>
          <el-form-item class="m-r-120" prop="code" label="设备编号">
            <el-input v-model="form.code" clearable size="mini" maxlength="6" placeholder="请输入设备名称" />
          </el-form-item>
          <el-form-item class="m-r-120" prop="projectType" label="设备类型">
            <el-select  size="mini" style="width:100%" v-model="form.projectType" clearable placeholder="请选择设备类型">
              <el-option v-for="item in pTypes" :key="item.name" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <biz-edit :data-obj="form" :is-admin="user.tenantId === 0" :is-detail="false" :is-add="true"
            @select-company="onSelectCompany" />
          <el-form-item prop="siteId" label="所属站点">
            <el-select style="width:100%" v-model="form.siteId"  placeholder="请选择所属站点" size="mini"
                filterable clearable
                @change="onSelectSite"
            >
                <el-option v-for="site in siteList" :key="site.id" :value="site.siteId" :label="site.siteName"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item  prop="area" label="所在地">
            <el-select placeholder="请选择所在地" v-model="form.area" style="width:100%;" size="mini">
              <el-option v-for="(area, index) in areaList" :key="index" :value="area" :label="area"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="m-r-120" prop="information" label="备注">
            <el-input v-model="form.information" clearable maxlength="50" size="mini" type="textarea" placeholder="请输入备注" />
          </el-form-item>
        </el-form>
        <dialog-footer reset-access="device-edit-reset-btn" submit-access="device-edit-edit-btn" @reset="onReset" @submit="onSubmit" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';


import siteApi from "@/api/site";
// import DevicePlateApi from '@/api/470/DevicePlate';
import { addDevices, editDevices } from '@/api/device';

import DeviceEditCode from "./code";
import BizEdit from "@/components/business/edit";

export default {
  components: {
    DeviceEditCode,
    BizEdit
  },
  props: {
    isDetail: {
      type: Boolean,
      default: false
    },
    isAdd: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      loading: true,
      isAdmin: false,
      siteId: "",
      height: "100%",
      areaList: $statics.AREA_LIST,
      pTypes: $statics.DEVICE_PROJECT_TYPE_LIST,
      company: null,
      siteList: [],
      siteMap: {},
      site: null,
      form: {
        gatewayState: 2,
        deviceName: '',
        projectType: "",
        site: "",
        code: "",
        information: '',
        businessName: "",
        businessId: "",
        tenantId: "",
        area: ""
      },
      rules: {
        deviceName:  [
          { required: true, message: '请输入设备名称', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (value && value.length > 50) {
                callback("设备名称请控制在 50 个字符以内！");
                return;
              }
              callback();
            }
          }
        ],
        tenantId: [
          { required: true, message: '请选择一个公司分类', trigger: 'change' }
        ],
        businessId: [
          { required: true, message: '请选择所属公司', trigger: 'change' }
        ],
        code: [
          { required: true, message: '请输入设备编号', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (!/^(\d{6})$/.test(value)) {
                callback("请使用六位数字来对设备进行编号");
              } else {
                callback();
              }
              
            },
            trigger: 'blur'
          }
        ],
        projectType: [
          { required: true, message: '请选择设备类型', trigger: 'blur' }
        ],
        area:  [
          { required: true, message: '请选择所在地', trigger: 'blur' }
        ]
      }
    }
  },
  watch: {
    isDetail: function (v) {
      this.height = v ? "100%" : "calc(100% - 40px)";
    }
  },
  computed: {
    ...mapGetters(['user'])
  },
  async created () {
    console.log("device-edit");

    await this.getSiteData();

    if (this.data !== null) {
      this.form = this.data;
    }
  },
  methods: {
    async onClosed () {
      return await $utils.closeDialogConfirm(this);
    },
    async getSiteData () {
      this.siteList = [];

      const params = {
        page: 0,
        size: 9999999
      };
      if (this.user.tenantId !== 0) { 
        params.staffCode = `${this.user.businessId}`;
      }

      const res = await siteApi.list(params);
      this.siteList = res.data;

      const siteMap = {};
      for (const site of this.siteList) {
        siteMap[site.siteId] = site;
      }
      this.siteMap = siteMap;

      this.$nextTick(() => {
        setTimeout(() => {
          this.onSelectSite();
          this.loading = false;
        }, 1000);
      });
    },
    async onSelectCompany (company) {
      this.company = company;

      let checkFlg = this.loading;

      if (!this.isAdd) {
        checkFlg = checkFlg && !!this.site;
      }

      if (checkFlg) {
        this.$nextTick(() => {
          setTimeout(() => {
            this.loading = false;
          }, 1000);
        });
      }

      if (!company) return;
      if (!$utils.checkNumberIsNotEmpty(this.form.projectType)) return;


    },
    onSelectSite () {
      const site = this.siteMap[this.form.siteId];
      this.site = site;
    },
    async onAdd () {
      try {
        const submitData = Object.assign({}, this.form);

        submitData.businessId = this.company.id;
        submitData.businessName = this.company.companyName;
        submitData.companyName = this.company.companyName;
        if (this.site) {
          submitData.siteId = this.site.siteId;
          submitData.siteName = this.site.siteName;
        }
        submitData.delFlag = 0;

        // await DevicePlateApi.save(submitData);
        await addDevices(submitData);

        return true;
      } catch (err) {
        this.$message.error(err.response.data.message);
        return false;
      }
    },
    async onEdit () {
      try {
        const submitData = Object.assign({}, this.form);
        if (this.site) {
          submitData.siteId = this.site.siteId;
          submitData.siteName = this.site.siteName;
        }
        submitData.businessId = this.company.id;
        submitData.businessName = this.company.companyName;
        submitData.companyName = this.company.companyName;

        // await DevicePlateApi.update(submitData);
        await editDevices(this.form.id, submitData);

        return true;
      } catch (err) {
        this.$message.error(err.response.data.message);
        return false;
      }
    },
    onSubmit () { // 提交函数
      this.$refs.form.validate(async (valid) => { // 数据校验
        if (!valid) return;  // 未通过校验则不继续执行

        const method = (this.data && this.data.id) ? "onEdit" : "onAdd";
        const type = (this.data && this.data.id) ? "编辑" : "新建";

        try {
          this.loading = true;
          const ret = await this[method]();

          if (ret) {
            this.$message.success(`设备${type}成功`);
            this.$emit("close-edit-modal");
          } else {
            this.$message.error(`设备${type}失败`);
          }
        } finally {
          this.loading = false;
        }
      });
    },
  }
}
</script>
<style lang="scss" scoped>
.edit-items-box {
  // height: 500px;
  overflow: auto;
}
</style>